import React from 'react';
import * as S from './Share.style';
import {
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaReddit,
  FaTwitter,
  FaShareAlt,
} from 'react-icons/fa';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from 'react-share';

function Share({ title, url, summary, socialTags }) {
  return (
    <S.Wrapper>
      <S.Container>
        {/* <S.ShareContainer>
          <FaShareAlt />
        </S.ShareContainer> */}
        <S.Text>SHARE</S.Text>

        <EmailShareButton url={url} subject={title}>
          <FaEnvelope title="Share in an email" />
        </EmailShareButton>
        <FacebookShareButton url={url} quote={summary} hashtag={socialTags}>
          <FaFacebook title="Share on Facebook" />
        </FacebookShareButton>
        <LinkedinShareButton
          url={url}
          title={title}
          summary={summary}
          source={url}
        >
          <FaLinkedin title="Share on LinkedIn" />
        </LinkedinShareButton>
        <RedditShareButton url={url} title={title}>
          <FaReddit title="Share on Reddit" />
        </RedditShareButton>
        <TwitterShareButton url={url} title={title} hashtags={socialTags}>
          <FaTwitter title="Share on Twitter" />
        </TwitterShareButton>
      </S.Container>
    </S.Wrapper>
  );
}

export default Share;
