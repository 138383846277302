import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* background: blue; */
  font-size: 22px;
  button {
    margin-left: 10px;
    svg {
      transition: color ${(props) => props.theme.animations.xs} ease-in-out;
    }
    :hover {
      svg {
        color: var(--color-primary);
      }
    }
  }
`;

export const ShareContainer = styled.div`
  opacity: 0.3;
`;

export const Text = styled.div`
  font-size: 15px;
  padding-bottom: 3px;
`;
