import styled from 'styled-components';
import Image from 'gatsby-image';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.article`
  position: relative;
  box-sizing: content-box;
  max-width: 680px;
  margin: 0 auto;
  padding: 0px 20px 20px 20px;
  border-radius: 2px;
  @media screen and (min-width: 480px) {
    padding: 0px 32px 32px 32px;
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 20px;
  text-transform: uppercase;
  a {
    text-decoration: none;
    font-weight: 700;
  }
  svg {
    font-size: 19px;
    margin-bottom: 2px;
  }
`;

export const BreadcrumbLast = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Img = styled(Image)`
  margin-bottom: 15px;
`;

export const Title = styled.h1`
  text-align: center;

  font-size: 40px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: initial;
  /* text-transform: uppercase; */
  @media screen and (min-width: 480px) {
    text-align: left;
  }
`;
export const Subtitle = styled.h2`
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
  @media screen and (min-width: 480px) {
    text-align: left;
  }
`;

export const Author = styled.p`
  margin: 0;
`;
export const DateDisplay = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  line-height: 1.1;
`;
export const Category3 = styled.span`
  font-size: 14px;
  font-weight: 300;
`;
export const Tags3 = styled.div``;
export const Content3 = styled.div``;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    flex-direction: row;
  }
`;

export const Left = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;

  @media screen and (min-width: 480px) {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    width: 50%;
    margin-bottom: 10px;
  }
`;
export const Right = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-right: 5px;
  @media screen and (min-width: 480px) {
    justify-content: flex-start;
    width: 50%;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    justify-content: flex-end;
    padding-top: 13px;
  }
`;

export const DetailsText = styled.div``;

export const Avatar = styled.img`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 0 0 2.5px var(--color-background), 0 0 0 4.5px;
  margin: 0 auto 10px auto;
  box-shadow: 0 0 0 2.5px var(--color-background), 0 0 0 4.5px;
  color: var(--color-primary);
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  @media screen and (min-width: 480px) {
    margin: 0;
    margin-right: 10px;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: var(--color-gray300);
  margin: 10px 0 30px 0;
`;

const SVG_MARGIN = 10;

export const PostNavigationContainer = styled.div`
  display: flex;
  div {
    display: flex;
    width: 50%;
  }
  a {
    display: flex;
    align-items: center;
    svg {
      font-size: 20px;
      color: var(--color-primary);
      margin-right: ${SVG_MARGIN}px;
    }
  }
  div:last-of-type {
    justify-content: flex-end;
    a {
      svg {
        margin: 0;
        margin-left: ${SVG_MARGIN}px;
      }
    }
  }
`;
