import React from 'react';
import { graphql, Link } from 'gatsby';
import * as S from './BlogPost.style';
// import SEO from '../../components/seo';
import NavMain from '../../components/NavMain';
import LayoutBlog from '../../components/LayoutBlog';
import Img from 'gatsby-image';
import ProfileImage from '../../assets/images/profile-image.jpg';
import {
  RiArrowRightSLine,
  RiMapPinUserLine,
  RiMapPinUserFill,
} from 'react-icons/ri';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { slugify } from '../../utils/utils';
import SEO from '../../components/SEO/SEO';
import Share from '../../components/Share';

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        author
        title
        subtitle
        summary
        socialTags
        image {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
      fields {
        slug
      }
    }
  }
`;

function BlogPost(props) {
  const { siteUrl } = props.data.site.siteMetadata;
  const { path } = props;
  const { mdx } = props.data;
  const {
    frontmatter: { title, subtitle, summary, socialTags, author, date, image },
    body,
  } = mdx;
  const { category, previous, next } = props.pageContext;

  return (
    <LayoutBlog>
      <SEO title={title} />
      <S.Wrapper>
        <S.Container>
          <S.Img fluid={image.childImageSharp.fluid} />
          <S.Breadcrumbs>
            <Link to="../../" title="Go back to main blog">
              Blog
            </Link>{' '}
            <RiArrowRightSLine />{' '}
            <Link to={`../`} title={`Go back to the ${category} category`}>
              {category}
            </Link>
            <RiArrowRightSLine />
            <RiMapPinUserFill title="You are here" />
            <S.BreadcrumbLast>{title}</S.BreadcrumbLast>
          </S.Breadcrumbs>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
          <S.DetailsWrapper>
            <S.Left>
              <S.Avatar src={ProfileImage} alt="Martin Kruger Profile Image" />
              <S.DetailsText>
                <S.Author>{author}</S.Author>
                <S.DateDisplay>
                  {date} - Posted in <Link to={`../`}>{category}</Link>
                </S.DateDisplay>
              </S.DetailsText>
            </S.Left>
            <S.Right>
              <Share
                title={title}
                url={`${siteUrl}${path}`}
                summary={summary}
                socialTags={socialTags}
              />
            </S.Right>
          </S.DetailsWrapper>
          <S.Separator />
          <MDXRenderer>{body}</MDXRenderer>
          {/* <S.Separator /> */}
          <S.PostNavigationContainer>
            <div>
              {previous && (
                <Link to={'/blog' + slugify(previous.fields.slug)}>
                  <FaChevronLeft />
                  {previous.frontmatter.title}
                </Link>
              )}
            </div>
            <div>
              {next && (
                <Link to={'/blog' + slugify(next.fields.slug)}>
                  {next.frontmatter.title}

                  <FaChevronRight />
                </Link>
              )}
            </div>
          </S.PostNavigationContainer>
        </S.Container>
      </S.Wrapper>
    </LayoutBlog>
  );
}

export default BlogPost;
